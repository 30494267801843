.navbar > nav {
  display: flex;
  align-items: center;
}

nav > a {
  margin: 1rem 1rem 1rem 0;
  padding: 1rem 1rem 1rem 0;
  text-decoration: none;
}

a.active {
  font-weight: 700;
}