.project-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
}

@media (max-width: 768px) {
  .project-container {
    grid-template-columns: 1fr;
  }
}

.projects-page .heading {
  color: var(--heading);
  font-size: 2.5rem;
  line-height: 2.4rem;
  letter-spacing: -0.04rem;
  margin: 20px 0;
}