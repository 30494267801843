.App {
  display: flex;
  justify-content: center;
}

.container {
  max-width: 700px;
  width: 100%;
  padding: 0 30px;
}

a {
  text-decoration: none;
}

.heading {
  color: var(--heading);
  font-size: 3rem;
  line-height: 2.4rem;
  letter-spacing: -0.04rem;
  margin: 10px 0;
}
.sub-heading {
  color: var(--sub-heading);
  font-size: 2.4rem;
  line-height: 2.4rem;
  letter-spacing: -0.04rem;
  margin: 10px 0;
}