.home-page .hero {
  display: flex;
  flex-direction: row;
}
@media (max-width: 720px) {
  .home-page .hero {
    flex-direction: column;
  }
  .home-page .profile {
    text-align: center;
    margin: 15px 0;
  }
}
.home-page .hero-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 40px;
}
.home-page .profile img {
  width: 260px;
  height: 260px;
  object-fit: cover;
  border-radius: 30px;
}
.home-page .heading {
  color: var(--heading);
  font-size: 3rem;
  line-height: 2.4rem;
  letter-spacing: -0.04rem;
  margin: 10px 0;
}
.home-page .sub-heading {
  color: var(--sub-heading);
  font-size: 2.4rem;
  line-height: 2.4rem;
  letter-spacing: -0.04rem;
  margin: 10px 0;
}